<template>
    <div class="login-main">         
        <div class="container">            
            <div class="vx-row justify-center">
                <!-- Login Left -->
                <div class="vx-col lg:w-3/4 w-full">
                    <div class="vx-row justify-center login-inner">
                        <div class="vx-col md:w-3/5 w-full pl-0">
                            <div class="login-form">
                                <h2>Login</h2>
                                <form data-vv-scope="loginForm">
                                    <div class="form-grp">
                                        <svg class="login-email" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" y1="446" x2="256" y2="70" gradientTransform="matrix(1 0 0 -1 0 514)">
                                                <stop  offset="0"/>
                                                <stop  offset="1"/>
                                            </linearGradient>
                                            <path style="fill:url(#SVGID_1_);" d="M452,68H60C26.916,68,0,94.916,0,128v256c0,33.084,26.916,60,60,60h392
                                            c33.084,0,60-26.916,60-60V128C512,94.916,485.084,68,452,68z M448.354,108L256,251.074L63.646,108H448.354z M452,404H60
                                            c-11.028,0-20-8.972-20-20V140.263l216,160.663l216-160.663V384C472,395.028,463.028,404,452,404z"/>
                                        </svg>
                                        <vs-input placeholder="Email" name="email" v-model="email" class="w-full" />                                           
                                    </div>
                                    <div class="form-grp">                                      
                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" y1="514" x2="256" y2="2" gradientTransform="matrix(1 0 0 -1 0 514)">
                                                <stop  offset="0"/>
                                                <stop  offset="1"/>
                                            </linearGradient>
                                            <path style="fill:url(#SVGID_1_);" d="M400,188h-36.037v-82.23c0-58.322-48.449-105.77-108-105.77s-108,47.448-108,105.77V188H112
                                            c-33.084,0-60,26.916-60,60v204c0,33.084,26.916,60,60,60h288c33.084,0,60-26.916,60-60V248C460,214.916,433.084,188,400,188z
                                            M187.963,105.77c0-36.266,30.505-65.77,68-65.77s68,29.504,68,65.77V188h-136L187.963,105.77L187.963,105.77z M420,452
                                            c0,11.028-8.972,20-20,20H112c-11.028,0-20-8.972-20-20V248c0-11.028,8.972-20,20-20h288c11.028,0,20,8.972,20,20V452z M293,323
                                            L293,323c0,13.08-6.793,24.565-17.037,31.145V398c0,11.045-8.955,20-20,20c-11.046,0-20-8.955-20-20v-43.902
                                            C225.76,347.51,219,336.048,219,323l0,0c0-20.435,16.565-37,37-37l0,0C276.435,286,293,302.565,293,323z"/>
                                        </svg>                         
                                        <vs-input type="password" placeholder="Password" v-model="password" class="w-full" />                                            
                                    </div>
                                    <div class="flex flex-wrap items-center justify-between w-full">
                                        <vs-checkbox v-model="rememberMyEmail" class="mb-3">Remember me</vs-checkbox>
                                        <router-link to="Forgotpassword" class="forgot-pass">Forgot Password? </router-link>                                        
                                    </div>
                                    <div class="flex flex-wrap items-center justify-center mt-6 w-full">
                                        <vs-button color="primary" id="user-login" :disabled="button_loading" type="gradient" 
                                        class="float-right">Login</vs-button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="vx-col md:w-2/5 w-full p-0">
                            <div class="login-right">
                                <h2> Welcome Back! </h2>
                                <p>Please login to your account</p>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            email: "",
            password: "",  
            active: false
        };
    },
    
}
</script>

<style lang="scss">
.login-main {
    align-content: flex-end;
    position: relative;
    min-height: 100vh;
    z-index: 1;
    padding: 24px 0px;
    display: flex;     
    align-items: center;   
    position: relative;     
    background: #edf0f7;       
    .login-inner{
        box-shadow: 0px 14px 30px 1px rgba(var(--vs-primary), 0.3);
        overflow: hidden;
        float: left;
        border-radius: 2.00em;
        border: 2px solid  rgba(var(--vs-primary), 0.4);
        width: 100%;            
        background-color: #ffffff;            
        position: relative;
        padding: 0px;
        margin: 0px;
        .login-right{
            float: left;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(var(--vs-primary), 1), rgba(94, 77, 128,1));
            margin-right: -1px;
            display: flex;
            align-content: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 24px;
            h2{
                float: left;
                width: 100%;
                margin-bottom: 40px;
                color: #ffffff;
                font-size: 32px;
                text-align: center;
                line-height: 45px;
                font-weight: 600;
            }
            p{
                float: left;
                width: 100%;
                font-size: 16px;
                color: #ffffff;
                text-align: center  ; 
            }
        }
         .login-form{
            height: 100%;
            display: flex;
            align-content: center;
            padding: 80px 72px;
            flex-wrap:wrap;
            h2{
                float: left;
                width: 100%;
                font-weight: 700;
                font-size: 32px;
                margin-bottom: 48px;    
                text-align: center;
                color: #333;               
            }
            form{
                float: left;
                width: 100%;
                .form-grp{
                    float: left;
                    width: 100%;
                    margin-bottom: 40px;
                    position: relative;     
                    &:nth-child(2){
                        margin-bottom: 20px;
                    }  
                    svg {
                        stop:first-child{
                            stop-color: rgba(var(--vs-primary), 1);
                        }
                        stop:last-child{
                            stop-color: #5e4d80;
                        }
                    }             
                    svg{
                        width: 32px;
                        height: 32px;
                        position: absolute;
                        left: 20px;
                        top: 10px;
                        z-index: 1;
                        &.login-email{
                            width: 29px;
                            top: 12px;
                        }                                                
                    }
                    .input-span-placeholder{
                        padding-left: 4.2rem !important;
                        font-weight: 500;
                        font-size: 14px;
                    }
                    .vs-inputx{
                        border: 0px !important;
                        box-shadow: rgba(var(--vs-primary), 0.4) -4px 5px 22px -2px;
                        background: #fff;
                        padding: 1.4em 1.5rem 1.4em 4.2rem !important;
                        border-radius: 2.00em;
                        &:focus{
                             box-shadow: rgba(var(--vs-primary), 0.6) -4px 4px 9px 0.5px;
                        }
                    }
                }
                .forgot-pass{
                    font-weight: 600;
                }
            }            
        } 
        
    }
}


/* Resposive */
@media (max-width: 991px) {
   .login-main .login-inner .login-form {
        padding: 40px 32px;
    }   
}

@media (max-width: 767px) {
    .login-inner .vx-col:first-child{
        order: 2;
    }
    .login-main .login-inner .login-right{
        padding:40px 24px;
    }  
    .login-main .login-inner .login-right h2{
        margin-bottom: 16px;
    }
    .login-main .login-inner .login-form {
        padding: 32px 24px;
    }
    .login-main .login-inner .login-form form .form-grp {
        margin-bottom: 16px;
    }    
    .login-main .login-inner .login-form form .form-grp svg{
        width: 24px;
        top: 7px;
    }
    .login-main .login-inner .login-form form .form-grp svg.login-email{
        width: 22px;
        top: 9px;
    }
    .login-main .login-inner .login-form form .form-grp .vs-inputx{
        padding: 1.1em 1.5rem 1.1em 4.2rem !important;
    }
}
@media (max-width: 575px) {
    .login-main .login-inner .login-form h2{
        margin-bottom: 24px;
        font-size: 24px;
    }
    .login-main .login-form form .form-grp{
        margin-bottom: 24px;
    }
    .login-main .login-form {
        padding: 24px 0px;
    }
    
}



input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {  
  -webkit-text-fill-color: #666;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
